<template>
  <div>
    <b-card>
      <progress-bar-status :progress="3" :status="progressBarStatus" />
    </b-card>

    <section class="bg-white p-3 rounded shadow-sm mt-3">
      <div class="d-flex flex-wrap align-items-start justify-content-between">
        <h1 v-if="mode === 'registrasi'">Diisi Oleh Aftap</h1>
        <h1 v-if="mode === 'edit' || mode === 'detail'">Hasil Aftap</h1>
        <div>
          <b-button v-if="mode === 'detail'" @click="mode = 'edit'"
            >Edit</b-button
          >
          <b-button
            v-if="mode === 'edit'"
            variant="danger"
            @click="mode = 'detail'"
          >
            Batal</b-button
          >
          <b-button
            v-if="mode === 'edit'"
            class="ml-1"
            variant="success"
            :disabled="isBusy || !isFormValid"
            @click="saveChanges"
          >
            <b-spinner v-if="isBusy" small />
            Simpan</b-button
          >
        </div>
      </div>
      <h5>
        Golongan Darah:
        <b>{{
          blood_type.replace(/\+|-/g, "") +
          (blood_type.includes("+") ? " Pos" : " Neg")
        }}</b>
      </h5>
      <hr />
      <b-form @submit.prevent="saveChanges">
        <b-row>
          <b-col cols="12" md="6">
            <h4>Keterangan Donasi</h4>
            <b-form-group label="Nomor Kantong">
              <b-form-input
                v-model="form.barcode"
                :disabled="mode === 'detail'"
                @input="checkBloodBag"
              />
              <p
                class="m-0"
                :class="
                  check_blood_bag_number === 'Barcode ditemukan!'
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{ check_blood_bag_number }}
              </p>
            </b-form-group>
            <!-- <b-form-group label="Nomor Selang">
              <b-form-input
                v-model="form.blood_bag_number"
                :disabled="mode === 'detail'"
              />
            </b-form-group> -->
            <b-form-group label="Cuci Lengan/Lap Tisu">
              <b-form-radio-group
                v-model="form.antiseptic_wipe"
                :disabled="mode === 'detail'"
              >
                <b-form-radio value="1"> Sudah </b-form-radio>
                <b-form-radio value="0"> Belum </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group label="Jam Mulai">
              <b-input-group class="mb-3">
                <b-form-input
                  id="start-time-input"
                  v-model="startTimeInput"
                  type="text"
                  placeholder="HH:mm"
                  :disabled="mode === 'detail'"
                  @input="onStartTimeInputChange"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="form.startTime"
                    :show-seconds="false"
                    button-only
                    right
                    locale="id"
                    aria-controls="start-time-input"
                    :disabled="mode === 'detail'"
                    @change="onStartTimepickerChange"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Jam Akhir">
              <b-input-group class="mb-3">
                <b-form-input
                  id="end-time-input"
                  v-model="endTimeInput"
                  type="text"
                  placeholder="HH:mm"
                  :disabled="mode === 'detail'"
                  @input="onEndTimeInputChange"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="form.endTime"
                    :show-seconds="false"
                    button-only
                    right
                    locale="id"
                    aria-controls="end-time-input"
                    :disabled="mode === 'detail'"
                    @change="onEndTimepickerChange"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <h4>Reaksi Donor</h4>
            <b-form-group label="Reaksi Pengambilan">
              <b-form-radio-group
                v-model="form.uptake_reaction"
                :disabled="mode === 'detail'"
              >
                <b-form-radio value="lancar"> Lancar </b-form-radio>
                <b-form-radio value="tidak_lancar"> Tidak Lancar </b-form-radio>
                <b-form-radio value="stop"> Stop </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              v-if="form.uptake_reaction === 'stop'"
              label="Detail Reaksi Pengambilan"
            >
              <b-form-input
                v-model="form.uptake_reaction_detail"
                :disabled="mode === 'detail'"
                placeholder="Masukkan Detail Reaksi Pengambilan"
              />
            </b-form-group>
            <b-form-group label="Reaksi Samping">
              <b-form-radio-group
                v-model="form.side_reaction"
                :disabled="mode === 'detail'"
              >
                <b-form-radio value="tidak ada"> Tidak Ada </b-form-radio>
                <b-form-radio value="hematoma"> Hematoma </b-form-radio>
                <b-form-radio value="pusing"> Pusing </b-form-radio>
                <b-form-radio value="muntah"> Muntah </b-form-radio>
                <b-form-radio value="lainnya"> Lainnya </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              v-if="form.side_reaction === 'lainnya'"
              label="Detail Reaksi Samping"
            >
              <b-form-input
                v-model="form.side_reaction_detail"
                :disabled="mode === 'detail'"
                placeholder="Masukkan Detail Reaksi Samping"
              />
            </b-form-group>
            <b-form-group label="Status">
              <b-form-checkbox
                v-model="form.status"
                switch
                :disabled="mode === 'detail'"
              >
                {{ form.status === true ? "Diterima" : "Ditolak" }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="form.status === false" label="Alasan Penolakan">
              <b-form-select
                v-model="form.reason_rejected"
                :disabled="mode === 'detail'"
              >
                <b-form-select-option value="mislek"
                  >Mislek</b-form-select-option
                >
                <b-form-select-option value="tidak jadi"
                  >Tidak Jadi</b-form-select-option
                >
                <b-form-select-option value="lain-lain"
                  >Lainnya</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="form.reason_rejected === 'lain-lain'"
              label="Catatan"
            >
              <b-form-textarea
                v-model="form.note"
                :disabled="mode === 'detail'"
                placeholder="Masukkan Catatan"
                :rows="4"
                :max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <b-button
            v-if="mode === 'registrasi'"
            class="ml-1"
            variant="success"
            :disabled="isBusy || !isFormValid"
            @click="saveChanges"
          >
            <b-spinner v-if="isBusy" small />
            Simpan</b-button
          >
        </div>
      </b-form>
    </section>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BFormSelect,
  BFormTimepicker,
  BRow,
  BFormSelectOption,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import debounce from "lodash/debounce";
import progressBarStatus from "../../components/ProgressBarStatus.vue";
import manageAftafAPI from "../../../../../../api/manageAftaf/manageAftafAPI";
import manageHbAPI from "../../../../../../api/manageHB/manageHbAPI";
import managePendonorAPI from "../../../../../../api/managependonor/managePendonorAPI";

export default {
  name: "AftapForm",
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormTimepicker,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    progressBarStatus,
  },
  data() {
    return {
      isBusy: false,
      mode: "detail",
      blood_type: "",
      check_blood_bag_number: "",
      progressBarStatus: "",
      startTimeInput: "",
      endTimeInput: "",
      form: {
        barcode: "",
        blood_bag_number: "",
        antiseptic_wipe: "",
        startTime: "",
        endTime: "",
        uptake_reaction: "",
        uptake_reaction_detail: "",
        side_reaction: "",
        side_reaction_detail: "",
        status: false,
        reason_rejected: "",
        note: "",
      },
      dataUser: {
        name: "",
        address: "",
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        (this.mode === "edit" ||
          this.check_blood_bag_number === "Barcode ditemukan!") &&
        this.form.barcode &&
        this.form.antiseptic_wipe &&
        this.form.uptake_reaction &&
        this.form.side_reaction &&
        this.form.startTime &&
        this.form.endTime
      );
    },
  },
  watch: {
    "form.barcode": function (newVal, oldVal) {
      this.checkBloodBag();
    },
    "form.uptake_reaction": function (newValue) {
      if (newValue === "lancar") {
        this.form.status = true;
      } else if (newValue === "tidak_lancar") {
        this.form.status = false;
      }
    },
    "form.startTime": {
      handler(newVal) {
        this.form.startTime = this.formatTime(newVal);
        this.startTimeInput = this.form.startTime;
      },
      immediate: true,
    },
    "form.endTime": {
      handler(newVal) {
        this.form.endTime = this.formatTime(newVal);
        this.endTimeInput = this.form.endTime;
      },
      immediate: true,
    },
  },
  mounted() {
    this.setInitialMode();
    if (this.mode === "registrasi") {
      this.fetchHB();
    } else {
      this.fetchDetailAftap();
    }
  },
  methods: {
    setInitialMode() {
      const route = this.$route.path;
      if (route.includes("/dashboards/aftap-registrasi/")) {
        this.mode = "registrasi";
      } else {
        this.mode = "detail";
      }
    },
    async fetchHB() {
      const { data: response } = await manageHbAPI.getDetail(
        this.$route.params.id
      );
      this.blood_type = response.data.blood_type;

      const registrationId = response.data.registration_id;

      const { data: responseUser } = await managePendonorAPI.getPendonor(
        registrationId
      );

      this.dataUser.name = responseUser.data[0].name;
      this.dataUser.address = `${responseUser.data[0].address}, ${responseUser.data[0].village} ${responseUser.data[0].district} ${responseUser.data[0].city} ${responseUser.data[0].province}`;
    },
    checkBloodBag: debounce(async function () {
      if (this.form.barcode) {
        try {
          const { data: response } = await manageAftafAPI.checkingBarcode({
            barcode: this.form.barcode,
          });
          this.check_blood_bag_number = response.message;
        } catch (error) {
          console.error("Error checking blood bag:", error);
          this.check_blood_bag_number = response.message;
        }
      }
    }, 1000),
    async fetchDetailAftap() {
      const { data: response } = await manageAftafAPI.getDetail(
        this.$route.params.id
      );
      this.blood_type = response.data.blood_type;
      this.form = response.data;
      this.progressBarStatus = response.data.status;
      this.uptake_reaction;
      if (
        !["lancar", "tidak lancar", "stop"].includes(this.form.uptake_reaction)
      ) {
        this.form.uptake_reaction = "stop";
        this.form.uptake_reaction_detail = response.data.uptake_reaction;
      }
      if (
        !["tidak ada", "hematoma", "pusing", "muntah", "lainnya"].includes(
          this.form.side_reaction
        )
      ) {
        this.form.side_reaction = "lainnya";
        this.form.side_reaction_detail = response.data.side_reaction;
      }
      this.form.startTime = response.data.venipuncture_start_time;
      this.form.endTime = response.data.venipuncture_end_time;

      this.form.status = ["Done", "Approved"].includes(this.form.status);
    },
    async saveChanges() {
      try {
        this.isBusy = true;
        const updatedFields = {
          blood_bag_number: this.form.blood_bag_number,
          barcode: this.form.barcode,
          antiseptic_wipe: this.form.antiseptic_wipe,
          time: this.form.time,
          uptake_reaction: this.form.uptake_reaction,
          uptake_reaction_detail:
            this.form.uptake_reaction !== "stop"
              ? ""
              : this.form.uptake_reaction_detail,
          side_reaction: this.form.side_reaction,
          side_reaction_detail:
            this.form.side_reaction !== "lainnya"
              ? ""
              : this.form.side_reaction_detail,
          status: this.form.status ? "Approved" : "Rejected",
          reason_rejected: this.form.status ? "" : this.form.reason_rejected,
          note:
            this.form.status || this.form.reason_rejected !== "lain-lain"
              ? ""
              : this.form.note,
        };

        const fieldsToSubmit = {
          barcode: updatedFields.barcode,
          blood_bag_number: updatedFields.blood_bag_number,
          antiseptic_wipe: updatedFields.antiseptic_wipe,
          time: updatedFields.time,
          uptake_reaction:
            updatedFields.uptake_reaction === "stop"
              ? updatedFields.uptake_reaction_detail
              : updatedFields.uptake_reaction,
          side_effect:
            updatedFields.side_reaction === "lainnya"
              ? updatedFields.side_reaction_detail
              : updatedFields.side_reaction,
          status: updatedFields.status,
        };

        const toastMessage =
          this.mode === "registrasi"
            ? "Data berhasil ditambahkan"
            : "Data berhasil diubah";

        try {
          if (this.mode === "registrasi") {
            await manageAftafAPI.add({
              hb_examinations_id: this.$route.params.id,
              ...fieldsToSubmit,
              venipuncture_start_time: this.form.startTime,
              venipuncture_end_time: this.form.endTime,
            });
          } else {
            await manageAftafAPI.edit({
              id: this.$route.params.id,
              hb_examinations_id: this.$route.params.id,
              ...fieldsToSubmit,
              venipuncture_start_time: this.form.startTime,
              venipuncture_end_time: this.form.endTime,
            });
          }

          this.$bvToast.toast(toastMessage, {
            title: "Sukses",
            variant: "success",
            autoHideDelay: 3000,
          });

          if (this.mode === "registrasi") {
            this.$router.push("/dashboards/aftap");
          }
        } catch (error) {
          this.$bvToast.toast(error.message, {
            title: "Gagal",
            variant: "danger",
            autoHideDelay: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    onStartTimeInputChange(value) {
      this.form.startTime = value;
    },
    onStartTimepickerChange(value) {
      this.form.startTime = value;
      this.startTimeInput = value;
    },
    onEndTimeInputChange(value) {
      this.form.endTime = value;
    },
    onEndTimepickerChange(value) {
      this.form.endTime = value;
      this.endTimeInput = value;
    },
    formatTime(value) {
      if (value) {
        return value.split(":").slice(0, 2).join(":");
      }
      return "";
    },
  },
};
</script>
